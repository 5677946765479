import React, {useMemo} from 'react';
import {Article} from '@app/help-center/articles/article';
import {getBootstrapData} from '@common/core/bootstrap-data/use-backend-bootstrap-data';
import {Link} from 'react-router-dom';
import {slugifyString} from '@common/utils/string/slugify-string';
import {Section} from '@app/help-center/categories/category';
import clsx from 'clsx';
import { Category } from '@paypal/paypal-js';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  article: Article;
  children?: React.ReactNode;
  section?: any;
  category?: any;
  // section?: any;
  className?: string;
  target?: string;
}
export function ArticleLink({
  article,
  children,
  section,
  category,
  className,
  target,
  ...linkProps
}: Props) {
  //@ts-ignore
  section = section ? section: article.sections[0];

  const link = useMemo(() => {
    return getArticleLink(article, {section}, {category});
  }, [article, section]);

  return (
    <Link
      className={clsx(
        'overflow-hidden overflow-ellipsis text-inherit outline-none transition-colors hover:underline focus-visible:underline',
        className,
      )}
      to={link}
      target={target}
      {...linkProps}
    >
      {children ?? article.title}
    </Link>
  );
}

interface Options {
  absolute?: boolean;
  section?: Section;
}

interface OptionsCategory {
  category?: any;
}
export function getArticleLink(
  article: {id: number; slug?: string; title: string; path?: Article['path']},
  {absolute, section}: Options = {},
  {category}: OptionsCategory = {},
): string {
  if (!section && article.path?.length) {
    section = article.path[1];
  }
  let link = `/articles/${category?.slug ? category?.slug :section?.parent?.slug }/${section?.slug}/${article.slug}`;


  if (absolute) {
    link = `${getBootstrapData().settings.base_url}${link}`;
  }
  return link;
}

export function getEditArticleLink(article: Article) {
  if (article.path?.length === 2) {
    return `/admin/hc/arrange/sections/${article.path[1].id}/articles/${article.id}/edit`;
  }
  return `/admin/articles/${article.id}/edit`;
}
