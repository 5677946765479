import React, {useMemo} from 'react';
import {getBootstrapData} from '@common/core/bootstrap-data/use-backend-bootstrap-data';
import {Link} from 'react-router-dom';
import {slugifyString} from '@common/utils/string/slugify-string';
import {Category, Section} from '@app/help-center/categories/category';
import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  category: Category | Section;
  children?: React.ReactNode;
  className?: string;
  target?: string;
}
export function CategoryLink({
  category,
  children,
  className,
  ...linkProps
}: Props) {
  const link = useMemo(() => {
    return getCategoryLink(category);
  }, [category]);

  return (
    <Link
      className={clsx(
        'overflow-hidden overflow-ellipsis text-inherit outline-none transition-colors hover:underline focus-visible:underline',
        className,
      )}
      to={link}
      {...linkProps}
    >
      {children ?? category.name}
    </Link>
  );
}

interface Options {
  absolute?: boolean;
}
export function getCategoryLink(
  object: Category | Section,
  {absolute}: Options = {},
): string {
  const category:Category = object as Category;
  const section:Section = object as Section;
  let link = object.is_section
    ? `/categories/${section.parent?.slug}/${section.slug}`
    : `/categories/${category.slug}`;

  if (absolute) {
    link = `${getBootstrapData().settings.base_url}${link}`;
  }
  return link;
}
